import { decode } from "iconv-lite";
import { Buffer } from "buffer";
import { detect } from "encoding-japanese";
import JSZip from "jszip";

// Mapping between the filename extensions and the content type sent to the API when uploading
// No need to setup the usual file extensions (jpeg)
const contentTypesByExtension = {
  ".svs": "image/tiff",
  ".ndpi": "image/tiff",
  ".isyntax": "image/isyntax",
  ".i2syntax": "image/isyntax"
};

export function getFileContentType({ name, type }): string {
  const extension = getFileExtension(name);

  const fileType = contentTypesByExtension[extension];

  if (fileType) {
    return fileType;
  }
  if (type) {
    return type;
  }
  throw new Error(`No file type associated to this extension "${extension}"`);
}

export function getFileExtension(filename: string): string {
  const index = filename.lastIndexOf(".");
  if (index !== -1) {
    return filename.slice(index);
  }
  return "";
}

const PDF_TYPE = {
  acceptedTypes: "application/pdf",
  acceptedExtensions: ".pdf"
};

const SUPPORTED_ATTACHMENT_IMAGE_TYPES = [
  {
    acceptedTypes: "image/png",
    acceptedExtensions: ".png"
  },
  {
    acceptedTypes: "image/jpeg",
    acceptedExtensions: ".jpeg"
  },
  {
    acceptedTypes: "application/zip",
    acceptedExtensions: ".zip"
  },
  {
    acceptedTypes: "image/jpeg",
    acceptedExtensions: ".jpg"
  }
] as const;

export const SUPPORTED_ATTACHMENT_TYPES_EXCLUDE_IMAGE = [
  PDF_TYPE,
  {
    acceptedTypes: "application/msword",
    acceptedExtensions: ".doc"
  },
  {
    acceptedTypes:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    acceptedExtensions: ".docx"
  },
  {
    acceptedTypes: "application/vnd.ms-powerpoint",
    acceptedExtensions: ".ppt"
  },
  {
    acceptedTypes:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    acceptedExtensions: ".pptx"
  },
  {
    acceptedTypes: "application/vnd.ms-excel",
    acceptedExtensions: ".xls"
  },
  {
    acceptedTypes:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    acceptedExtensions: ".xlsx"
  },
  {
    acceptedTypes: "text/csv",
    acceptedExtensions: ".csv"
  },
  {
    acceptedTypes: "application/zip",
    acceptedExtensions: ".zip"
  },
  {
    acceptedTypes: "image/gif",
    acceptedExtensions: ".gif"
  },
  {
    acceptedTypes: "image/bmp",
    acceptedExtensions: ".bmp"
  }
] as const;

export const SUPPORTED_ATTACHMENT_TYPES = SUPPORTED_ATTACHMENT_TYPES_EXCLUDE_IMAGE.concat(
  SUPPORTED_ATTACHMENT_IMAGE_TYPES
);

export function isPDF(filename: string): boolean {
  return checkFileExtension(filename, PDF_TYPE.acceptedExtensions);
}

export function isAttachments(filename: string): boolean {
  return SUPPORTED_ATTACHMENT_TYPES.some(file =>
    checkFileExtension(filename, file.acceptedExtensions)
  );
}

export function isAttachmentsExcludeImage(filename: string): boolean {
  return SUPPORTED_ATTACHMENT_TYPES_EXCLUDE_IMAGE.some(file =>
    checkFileExtension(filename, file.acceptedExtensions)
  );
}

async function retrieveZipFileNames(file: File): Promise<string[]> {
  const zip = await JSZip.loadAsync(file, {
    decodeFileName: function(bytes) {
      var buffer: Buffer;
      var detectedEncoding;
      if (bytes instanceof Uint8Array) {
        detectedEncoding = detect(bytes);
        buffer = Buffer.from(bytes);
      } else if (bytes instanceof Buffer) {
        detectedEncoding = detect(new Uint8Array(bytes));
        buffer = bytes;
      } else {
        return "";
      }
      if (detectedEncoding === false) {
        return "";
      }
      return decode(buffer, detectedEncoding);
    }
  });
  return Object.keys(zip.files);
}

export async function isValidVsiZipFile(
  file: File
): Promise<[boolean, string]> {
  if (!checkFileExtension(file.name, ".zip")) {
    return [false, ""];
  }
  var vsiFilename, vsiDirname;
  var filenameIndex = Number.MAX_VALUE;
  var dirNames: string[] = [];
  var entryNames = await retrieveZipFileNames(file);
  for (var entry of entryNames) {
    if (entry.endsWith(".vsi")) {
      var dirArray = entry.split("/");
      if (filenameIndex > dirArray.length - 1) {
        filenameIndex = dirArray.length - 1;
        vsiFilename = dirArray[filenameIndex];
      }
    } else if (entry.endsWith("/")) {
      dirNames.push(entry);
    }
  }

  if (filenameIndex === Number.MAX_VALUE) {
    return [false, ""];
  }

  for (var i = 0; i < dirNames.length; i++) {
    var subs = dirNames[i].split("/");
    if (subs.length <= filenameIndex) {
      continue;
    }
    if (
      subs[filenameIndex] ===
      "_" + vsiFilename.substring(0, vsiFilename.length - 4) + "_"
    ) {
      vsiDirname = dirNames[i];
      break;
    }
  }

  if (!vsiDirname) {
    return [false, ""];
  }
  return [true, vsiFilename];
}

export const checkFileExtension = (
  filename: string,
  expectedExtension: string
): boolean => {
  return (
    expectedExtension ===
    filename
      .toLowerCase()
      .split(/(?=\.)/)
      .pop()
  );
};

export const readFile = async (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      if (e.target?.result instanceof ArrayBuffer) {
        resolve(e.target.result);
      } else {
        reject(new Error("Failed to load file"));
      }
    };
    reader.onerror = e => {
      reject(e);
    };
    reader.readAsArrayBuffer(file);
  });
};
